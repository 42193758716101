
import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = window.APIBasePath;

/**
 * 更新动态
 * @param {string} params
 * @param {string} params.uuid 项目uuid
 * @param {string} params.content 项目更新描述内容
 * @param {array} params.images 图片数组
 *
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93468503
*/
const postPublishData = function (params) {
    const url = `${baseurl}mmc/project/dynamic/publish`;
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: params,
    });
}

export {
    postPublishData
}
