<template>
    <div class="modal-guide">
        <div class="modal-content">
            <div class="modal-header bold">更新动态</div>
            <div class="modal-body">
                <p class="label bold">发布更新有什么作用？</p>
                <ul class="brief">
                    <li>1、便于支持者及时了解宠物治疗近况</li>
                    <li>2、通过更新动态表达对支持者的感谢</li>
                    <li>3、增加项目可信度获得更多爱心支持</li>
                    <li>4、筹款中有特殊情况可更新告知大家</li>
                </ul>

                <p class="label bold">发布后会展示在项目详情页面如下图</p>
                <img src="./img/update-feed.png" />
                <!-- <p class="label bold">更新动态内容包括：</p>
                <ul >
                    <li>1、患者最新治疗情况文字说明+图片</li>
                    <li>2、治疗费用使用文字说明+缴费单图片</li>
                    <li>3、呼吁转发和感谢大家的话语等</li>
                </ul> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" @click="$emit('closeModal')">知道了</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GuideModal',
    data () {
        return {}
    }
}
</script>

<style lang='less' scoped>
.modal-guide{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0,0,0,0.85);

    .bold {
        font-weight: 600;
    }

    .modal-content  {
        background: #fff;
        width: 90%;
        border-radius: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
         overflow: hidden;
    }

     .modal-header {
        padding: 20px 0;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333333;
     }

    .modal-body {
        overflow: hidden;
        overflow-y: scroll;
        font-size: 14px;
        padding: 0 15px 54px;
        height: 85vh;
        overflow: hidden;
        overflow-y: scroll;
        text-align: left;
        -webkit-overflow-scrolling: touch;

        .label {
            color: #0099FF;
            padding-bottom: 15px;
        }
        li {
            color: #666;
            line-height: 22px;
        }
        .brief {
            padding-bottom: 15px;
        }
        img {
            margin-bottom: 25px;
            display: block;
            box-shadow: 0 3px 12px 0 rgba(183,194,180,0.6);
        }
    }

    .modal-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .btn {
        display: block;
        background:#fff;
        border: none;
        width: 100%;
        color: #0099FF;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #0099FF;
        text-align: center;
        border-top: 1px solid #ccc;
        padding: 15px 0;
        line-height: 1;
    }
}
</style>
