<template>
    <div class="update-dynamic">
        <div class="top-tips">
            <strong>提示:</strong>请勿在更新内容中上传支付二维码或引导用户发微信、支付宝红包、私人账号汇款等信息，一经发现，将严肃处理
        </div>

        <div class="content-warpper">
            <div class="content-title">
                <span class="bold">更新内容</span>
                <a href="javascript:;" @click="changeGuideModal">如何写</a>
            </div>

            <div class="textarea-warpper modal-text">
                <textarea
                    v-model="content"
                    class="control-input "
                    rows="5"
                    placeholder="请填写更新动态内容（10个字以上）"
                    maxlength="120">
                </textarea>
                <div class="modal-count">
                    <span class="c-s-1">{{content.length}}</span>/<span class="c-s-2">120</span>
                </div>
            </div>

            <!-- <div class="update-img">
                <img src="./img/add-icon.png" />
                <div class="cell-rt">点击上传更新图片</div>
            </div> -->
            <div class="update-label">上传图片（可选）</div>
            <mmcUploaderDefault
                class="mt20"
                @changeend="onPoorImgChange"
                :maxNum="8"
            ></mmcUploaderDefault>

            <div class="footer-btn">
                <button @click="updataProjectDynamic">提交</button>
            </div>
        </div>

        <GuideModal v-if="showGuideModal" @closeModal="changeGuideModal" />
    </div>
</template>

<script>
import GuideModal from './GuideModal';
import mmcUploaderDefault from '@/components/mmcUI/mmc-uploader-default';
import { postPublishData } from './js/api.js';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';

export default {
    name:'updateDynamic',
    data () {
        return {
            content: '',
            images: [],
            showGuideModal: false,
        }
    },
    components: {
        GuideModal,
        mmcUploaderDefault,
    },

    created() {
       authChecker.check({ notneedphone: 1 }, (res) => {
           shareCtrl.init();
       })
    },
    methods: {
        changeGuideModal() {
            this.showGuideModal = !this.showGuideModal;
        },
        onPoorImgChange(arr) {
            this.images = arr;
        },

        updataProjectDynamic() {
            let reg = /^[a-zA-Z]+$/g;

            let content = this.content.trim();
            if(content == ''|| content == null){
                alertMsg('更新内容不能为空');
                return;
            }

            if(content.length < 10){
                alertMsg('更新内容不得少于10个字');
                return;
            }

            if (reg.test(content)) {
                alertMsg('更新内容不能全为字母');
                return false;
            }

            if(content.length > 120){
                alertMsg('更新内容不得大于120个字');
                return;
            }

            const projuuid = this.$route.query.projuuid;
            const parmas = {
                uuid: projuuid,
                content: this.content,
                images: this.images,
            }
            postPublishData(parmas).then(res => {
                if(res.code === 0) {
                    alertMsg('项目动态发布成功', 1200);

                    setTimeout(() => {
                        location.href = `${window.BasePath}fund/project/detail?projuuid=${projuuid}`;
                    }, 1200)
                }
            });
        }
    }
}
</script>

<style lang='less' scoped>

.update-dynamic {
    .bold {
        font-weight: 600;
    }

    .top-tips {
        color: #FF8919;
        font-size: 13px;
        line-height: 20px;
        padding: 10px 15px;
        background: #F8F7F4;
    }

    .content-title {
        color: #333;
        font-size: 20px;
        margin-bottom: 19px;
        text-align: left;
        font-weight: 600;

        a {
            display: inline-block;
            font-size: 12px;
            color: #0099FF;
            text-decoration: underline;
            margin-left: 10px;
            position: relative;
            top: -2px;
        }
    }

    .content-warpper {
        padding: 19px 15px;
        .textarea-warpper {
            margin-bottom: 20px;
            padding-bottom: 4px;
            border-bottom: 1px solid #E5E5E5;
        }
        .control-input {
            width: 100%;
            color: #333;
             border: none;
        }
        .modal-count {
            text-align: right;
            color: #ccc;
            font-size: 13px;
        }
    }

    .update-img {
        position: relative;
        background: #F7F7F7;
        border-radius: 4px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E5E5E5;
        padding: 18px 0;
        img {
            width: 20px;
            height: auto;
            margin-right: 12px;
        }
        .cell-rt {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #0099FF;
        }
    }

    .update-label {
        color: #333;
        font-size: 18px;
        text-align: left;
        font-weight: 600;
        margin-bottom: 19px;
    }

    .footer-btn {
        padding-top: 30px;

        button {
            width: 100%;
            display: block;
            padding: 10px;
            text-align: center;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            border: none;
            border-radius: 4px;
            background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
            box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        }
    }
}


</style>
